import React, { Fragment, useRef } from 'react'

const logo = '/oxai-logo.png'
const socials = [
  {
    name: 'Twitter',
    link: 'https://twitter.com/OxAIOxAI',
  },
  {
    name: 'Telegram',
    link: 'https://t.me/OxAInews',
  },
  {
    name: 'Discord',
    link: 'https://discord.gg/XUd76cWRHR',
  },
  {
    name: 'Medium',
    link: 'https://medium.com/@OxaiOxai',
  },
]

function App() {
  const logoRef = useRef(null)
  const overlayRef = useRef(null)
  const contentRef = useRef(null)

  const handleLoadLogo = () => {
    logoRef.current.classList.add('animate-zooom')
    overlayRef.current.classList.add('animate-fade-out')
    overlayRef.current.classList.remove('opacity-1')
    overlayRef.current.classList.add('opacity-0')
    overlayRef.current.classList.add('-z-10')
    contentRef.current.classList.add('z-10')
  }

  return (
    <div className="relative w-full h-screen bg-teal-100 -z">
      <div ref={overlayRef} className="fixed opacity-1 z-10 w-full h-full bg-[#0D79BE]" />
      <div
        ref={contentRef}
        className="relative flex flex-col items-center justify-center w-full h-full gap-5 max-w-2xl mx-auto p-5"
      >
        <img ref={logoRef} src={logo} className="w-52 h-52" alt="logo" onLoad={handleLoadLogo} />
        <div className="text-5xl font-bold">OxAI</div>
        <div className="text-xl font-semibold text-center">
          Infrastructure Protocol for Decentralised AI Computation
        </div>
        <div className="flex justify-center items-center">
          {socials.map((social, i) => (
            <Fragment key={social.name}>
              <a href={social.link} target="_blank" rel="noreferrer" className="text-[#0D79BE] font-semibold">
                {social.name}
              </a>
              {i === socials.length - 1 ? null : <span className="mx-1">|</span>}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default App
